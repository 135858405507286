import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export default function RadioGroup(props) {
  const { name, label, value, onChange, items, isRowDir } = props;
  return (
    <FormControl
      className="ratio-btn-container"
      sx={{
        color: "black",
      }}
    >
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup
        row={isRowDir}
        className="ratio-btn"
        name={name}
        value={value}
        onChange={onChange}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id}

            control={<Radio sx={{
              color: "gray",
              "&.Mui-checked": {
                color: "black"
              }
            }} />}

            label={<Typography color="black">{item.title}</Typography>}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
